const apiUrl = "https://api-platerecognizer.stg.jivro.com/api/Webhook?pageNumber=1&pageSize=40";
//const apiUrl = "https://localhost:5001/api/Webhook?pageNumber=1&pageSize=10";
const fetchBookingCarsFromApi = async () => {
  try {
    const response = await fetch(apiUrl);
    const data = await response.json();

    if (data != null && data !== undefined) {
      const clientCars = data.map(item => {
        const jsonData = JSON.parse(item?.json[0]);
        const result = jsonData?.data?.results[0];

        // Convert timestamp from UTC to user's local time by applying the offset
        const timestampUTC = new Date(item.timestamp);
        const userOffset = timestampUTC.getTimezoneOffset() * 60000; // Offset in milliseconds
        const localTimestamp = new Date(timestampUTC.getTime() - userOffset);

        const formattedDate = `${localTimestamp.getMonth() + 1}/${localTimestamp.getDate()}/${localTimestamp.getFullYear()}`;
        const formattedTime = `${localTimestamp.getHours()}:${localTimestamp.getMinutes() < 10 ? '0' : ''}${localTimestamp.getMinutes()}`;

        return {
          id: item.id,
          camera: jsonData?.data?.camera_id,
          carName: result?.model_make[0]?.model,
          category: result?.model_make[0]?.make,
          type: result?.vehicle?.type,
          location: result?.region?.code,
          plate: result?.plate,
          imgUrl: `${item.image1}`,
          imgUrl2: `${item.image2}`,
          date: `${formattedDate} ${formattedTime}`
        };
      });
      return clientCars;
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

// Function to fetch data periodically
const fetchDataPeriodically = async () => {
  try {
    const clientCars = await fetchBookingCarsFromApi();
    console.log("Updated data:", clientCars);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

// Fetch data initially
fetchDataPeriodically();

// Fetch data every 10 seconds
setInterval(fetchDataPeriodically, 10000);

export default fetchBookingCarsFromApi;

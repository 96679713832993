import React, { useState, useEffect } from "react";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import "../styles/clients.css";
import fetchBookingCarsFromApi from "../assets/dummy-data/client-cars.js"; // Adjust the path if needed
import CarItem from "../components/UI/CarItem.jsx";

const Clients = () => {
  const [carData, setCarData] = useState([]);
  const [selectedCarIndex, setSelectedCarIndex] = useState(null); // Track the index of the selected car
  const [selectedButtons, setSelectedButtons] = useState([]);
  const [selectedCarName, setSelectedCarName] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchBookingCarsFromApi();
        if (data && data.length > 0) {
          setCarData(data);
        }
      } catch (error) {
        console.error("Error fetching client cars:", error);
      }
    };

    fetchData();
    const interval = setInterval(fetchData, 30000);
    return () => clearInterval(interval);
  }, []);

  const moveCar = (dragIndex, hoverIndex) => {
    const draggedCar = carData[dragIndex];
    const updatedCars = [...carData];
    updatedCars.splice(dragIndex, 1);
    updatedCars.splice(hoverIndex, 0, draggedCar);
    setCarData(updatedCars);
  };

  const toggleClicked = (buttonText) => {
    if (selectedButtons.includes(buttonText)) {
      setSelectedButtons(selectedButtons.filter(text => text !== buttonText));
    } else {
      setSelectedButtons([...selectedButtons, buttonText]);
    }
  };

  const handleCarClick = (carName, index) => {
    setSelectedCarName(carName); // Update the selected car name
    setSelectedCarIndex(index); // Update the selected car index
  };

  return (
    <div className="clients">
      <div className="client__wrapper">
        <div className="client__car-list">
          <DndProvider backend={HTML5Backend}>
            {carData.map((item, index) => (
              <CarItem
                key={item.id}
                index={index}
                item={item}
                moveCar={moveCar}
                isSelected={index === selectedCarIndex}
                onCarClick={(carName) => handleCarClick(carName, index)}
              />
            ))}
          </DndProvider>
        </div>
        
      </div>
    </div>

  );
};

export default Clients;

import React, { useEffect, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import '../styles/carItem.css';

const CarItem = ({ item, index, moveCar, isSelected, onCarClick }) => {
  // Set default values to avoid null exceptions
  const { 
    category = 'Unknown Category', 
    type = 'Unknown Type', 
    plate = 'Unknown Plate', 
    imgUrl = '', 
    imgUrl2 = '', 
    camera = 'Unknown Camera',
    carName = 'Unknown Car', 
    location = 'Unknown Location', 
    date = new Date().toISOString() 
  } = item || {};  // Fallback to an empty object if `item` is null or undefined

  // Null-safe uppercasing
  const upperCasePlate = plate ? plate.toUpperCase() : 'UNKNOWN PLATE';
  const upperCaseLocation = location ? location.toUpperCase() : 'UNKNOWN LOCATION';
  const upperCaseCamera = camera ? camera.toUpperCase() : 'UNKNOWN CAMERA';

  const [{ isDragging }, drag] = useDrag({
    type: 'CAR',
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: 'CAR',
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      moveCar(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const ref = React.useRef(null);
  drag(drop(ref));

  const handleClick = () => {
    if (onCarClick && typeof onCarClick === 'function') {
      onCarClick(carName); // Ensure the click handler is passed and is a function
    }
  };

  // State to track the time in the queue
  const [timeInQueue, setTimeInQueue] = useState('');
  const [isAlert, setIsAlert] = useState(false); // State to track alert condition

  useEffect(() => {
    // Function to calculate the time difference
    const calculateTimeInQueue = () => {
      const carEntryTime = new Date(date); // Convert the `date` to a Date object
      const currentTime = new Date(); // Get the current UTC time
      const timeDiffMs = currentTime - carEntryTime; // Time difference in milliseconds
      const timeDiffMinutes = Math.floor(timeDiffMs / 60000); // Convert to minutes
      const timeDiffHours = Math.floor(timeDiffMinutes / 60); // Convert to hours

      // Update the alert state and time in queue
      if (timeDiffMinutes > 10) {
        setIsAlert(true); // Set alert state to true if more than 10 minutes
      } else {
        setIsAlert(false); // Reset alert state if not
      }

      // Format the time in queue
      if (timeDiffHours > 0) {
        setTimeInQueue(`${timeDiffHours}h ${timeDiffMinutes % 60}m`);
      } else {
        setTimeInQueue(`${timeDiffMinutes}m`);
      }
    };

    // Call the function immediately
    calculateTimeInQueue();

    // Optionally, update the time every minute
    const interval = setInterval(calculateTimeInQueue, 60000);

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, [date]);

  return (
    <div
      ref={ref}
      className={`car__item ${isDragging ? 'dragging' : ''} ${isSelected ? 'selected' : ''} ${isAlert ? 'alert' : ''}`} // Add alert class conditionally
      onClick={handleClick}
    >
      <div className="car__item-top">
        <div className="car__item-tile">
          <h3>{carName}</h3>
          <span>
            <i className="ri-list-ordered"></i>
          </span>
        </div>
        <p>{category}</p>
      </div>

      <Tooltip
        html={<img src={imgUrl2 || imgUrl} alt={carName} style={{ width: '100%' }} />}  // Fallback for missing imgUrl2
        position="bottom"
        trigger="mouseenter"
        interactive
        arrow={true}
      >
        <div className="car__img" style={{ height: '200px' }}>
          <img
            src={imgUrl || 'https://via.placeholder.com/200'} // Fallback to placeholder if imgUrl is missing
            alt={carName}
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        </div>
      </Tooltip>

      <div className="car__item-bottom">
        <div className="car__bottom-left">
          <p>
            <i className="ri-map-2-line"></i> {upperCaseLocation}
          </p>
        </div>
        <p className="car__rent plate">{upperCasePlate}</p>
      </div>

      <div className="car__item-bottom">
        <div className="car__bottom-left">
          <p>
            <i className="ri-time-line"></i> {new Date(date).toLocaleString()}  {/* Safely display date */}
          </p>
        </div>
        <div className="car__bottom-left">
          <p>
            <i className="ri-car-washing-fill"></i> {type}
          </p>
        </div>
      </div>

      {/* Displaying camera name */}
      <div className="car__item-bottom">
        <div className="car__bottom-left">
          <p>
            <i className="ri-camera-line"></i> {upperCaseCamera} {/* Camera icon */}
          </p>
        </div>
      </div>

      {/* Displaying time in the queue */}
      <div className="car__item-bottom">
        <div className="car__bottom-left">
          <p>
            <i className="ri-timer-fill"></i> In Queue: {timeInQueue} {isAlert && <span style={{ color: 'red' }}> - Alert: Long Wait!</span>} {/* Add alert message */}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CarItem;
